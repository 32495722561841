<template>
  <div class="mobile-container">
    <mobileStatusBar title="支付成功" />
    <div class="icon img-cover"><img src="../../assets/images/success-icon.png"></div>
    <div class="title">支付成功</div>
    <router-link class="btn" :to="{path: '/'}">返回首页</router-link>
  </div>
</template>

<script>

import { mapState } from 'vuex'
import mobileStatusBar from '../../components/mobileStatusBar.vue'
 
export default {
  name: "Success",
  components: {
    mobileStatusBar
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapState(['userInfo'])
  },
  mounted () {
  },
  methods: {
    
  },
};
</script>

<style lang="less" scoped>
.mobile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .icon {
    margin-top: 1.6rem;
    width: 1.6rem;
    height: 1.6rem;
  }
  .title {
    margin-top: .48rem;
    color: #030304;
    font-size: .32rem;
    font-weight: 500;
  }
  .btn {
    cursor: pointer;
    margin-top: 4rem;
    width: 4.8rem;
    height: .8rem;
    border-radius: .12rem;
    background-color: #bac0cc;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .24rem;
    font-weight: bold;
  }
}
</style>
